import classNames from "classnames/bind";
import React from "react";

import styles from "./grid.module.scss";

const cx = classNames.bind(styles);

export interface Props {
    autoFill?: boolean;
    children: React.ReactNode;
    fullWidth?: boolean;
    fullHeight?: boolean;
}

const Grid: React.FC<Props> = ({ autoFill, children, fullHeight, fullWidth }: Props) => {
    const gridStyles = cx({
        [styles.responsiveGrid]: true,
        [styles.fullWidth]: fullWidth,
        [styles.fullHeight]: fullHeight,
        [styles.autoFill]: autoFill,
    });
    return <div className={gridStyles}>{children}</div>;
};

export default Grid;
